import { useEffect } from 'react';
import '../styles/globals.css';
import { appWithTranslation } from 'next-i18next';
import nextI18nConfig from '../next-i18next.config';
import AzureAppInsights from '../components/general/AzureAppInsights';
import { wrapper } from '../store/index';
import { Provider } from 'react-redux';
import { useRouter } from 'next/router';

const App = ({ Component, ...rest }) => {
  const { store, props } = wrapper.useWrappedStore(rest);
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => {
      const siteTheme = props.pageProps?.metadata?.siteTheme;
      if (siteTheme) {
        document.body.className = siteTheme;
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    // Apply theme on initial load
    handleRouteChange();

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events, props.pageProps?.metadata?.siteTheme]);

  return (
    <AzureAppInsights>
      <Provider store={store}>
        <Component {...props.pageProps} />
      </Provider>
    </AzureAppInsights>
  );
};

// https://github.com/i18next/next-i18next#unserializable-configs
export default appWithTranslation(App, nextI18nConfig);
