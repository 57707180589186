import { configureStore } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';
import { HYDRATE } from 'next-redux-wrapper';

const indexSlice = createSlice({
  name: 'app',
  initialState: {
    pageId: null,
    host: null,
    protocol: null,
    virtualHost: null,
    isRobot: false,
    siteTheme: null,
  },
  reducers: {
    setPageData: (state, action) => {
      state.pageId = action.payload.pageId;
      state.host = action.payload.host;
      state.isRobot = action.payload.isRobot;
      state.virtualHost = action.payload.virtualHost;
      state.protocol = action.payload.protocol;
      state.siteTheme = action.payload.siteTheme;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action) => {
      return (state = {
        ...state,
        ...action.payload.app,
      });
    });
  },
});

export const store = () =>
  configureStore({
    reducer: {
      app: indexSlice.reducer,
    },
  });

export const { setPageData } = indexSlice.actions;
export const wrapper = createWrapper(store, { debug: false });

export const selectPageId = (state) => state.app.pageId;
export const selectHost = (state) => state.app.host;
export const selectProtocol = (state) => state.app.protocol;
export const selectVirtualHost = (state) => state.app.virtualHost;
export const selectIsRobot = (state) => state.app.isRobot;
export const selectSiteTheme = (state) => state.app.siteTheme;
